import { Card, CardBody } from '@paljs/ui/Card';
import { InputGroup } from '@paljs/ui/Input';
import { Button } from '@paljs/ui/Button';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import '../../styles/common.css';
import { CSVLink, CSVDownload } from 'react-csv';
import Select from '@paljs/ui/Select';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  baseUri,
  showLoader,
  hideLoader,
  numFormatter,
  splitByChar,
  authCheck,
  dateFormat,
  dateGreaterOrEqual,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const ActionBtn = styled(Button)`
  padding: 4px;
  margin-bottom: 10px;
`;

const SelectField = styled(Select)`
  margin-bottom: 10px;
`;

const MachineUtilizationReportPage = () => {
  const [itemData, setItemData] = useState();
  const [showDownload, setShowDownload] = useState(false);
  const [tableFilter, setTableFilter] = useState('');
  const [tableData, setTableData] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [defaultCustomer, setDefaultCustomer] = useState([{ label: 'All', value: '' }]);
  const [userOptions, setUserOptions] = useState([]);
  const [customerSort, setCustomerSort] = useState([]);
  const [defaultCustomerSort, setDefaultCustomerSort] = useState([{ label: 'All', value: '' }]);
  const [sortOptions, setSortOptions] = useState([]);
  const state = useContext(GlobalStateData);
  useEffect(() => {
    authCheck(state.authData);
    getCustomers();
    getSorts('');
    getMachines();
  }, []);

  useEffect(() => {
    if (tableData.length) {
      setShowDownload(true);
    } else {
      setShowDownload(false);
    }
  }, [tableData]);

  const [machinData, setMachineData] = useState([]);
  const [defaultMachine, setDefaultMachine] = useState([{ label: 'All', value: '' }]);
  const [machineOptions, setMachineOptions] = useState([]);
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showData, setShowData] = useState([]);

  const handleModalOpen = (id) => {
    document.getElementsByTagName('body')[0].style.position = 'relative';
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    let match = [];
    match = tableData.find((o, i) => {
      if (o.id == id) {
        return true;
      }
    });
    setShowData(match);
    setIsModalOpen(true);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const handleModalClose = (event) => {
    setIsModalOpen(false);
    document.getElementsByTagName('body')[0].style.position = 'unset';
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  };

  const modalWrapperClick = (e) => {
    if (e.target.classList.contains('popup-parent')) {
      handleModalClose();
    }
  };

  const postApi = () => {
    const postData = {
      data: {
        customer,
        customerSort,
        machinData,
        from,
        to,
      },
    };
    let validate = requireCheck(postData);
    if (from && to) {
      var deliveryDateValid = dateGreaterOrEqual(to, from, 'to', 'Invalid To Date');
    } else {
      var deliveryDateValid = true;
    }

    if (!validate || !deliveryDateValid) {
      return false;
    }
    let cust = customer.toString();
    let sort = customerSort.toString();
    let machinDataVal = machinData.toString();
    showLoader();
    axios
      .get(
        baseUri() +
          'machine-utilization/report?machine=' +
          machinDataVal +
          '&user=' +
          cust +
          '&userSort=' +
          sort +
          '&from=' +
          from +
          '&to=' +
          to,
        {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        setItemData(response.data.data.report);
        const totData={
          hour:0,
          min:0,
          sec:0,
          material:0
        }
        response.data.data.report.forEach((data:any)=>{
          totData.material+=data.material;
          const timeArr:Array<string>=data.machine_hours.split(":");
          totData.hour+=+timeArr[0];
          totData.min+=+timeArr[1];
          totData.sec+=+timeArr[2];
        })
        const totObj={
          "machine_code": "",
          "machine_name": "",
          "lot_number": "",
          "customer_name": "Total",
          "sort_name": "",
          "material": totData.material,
          "group_name": "",
          "operation_name": "",
          "start_time": "",
          "end_time": "",
          "machine_hours": `${totData.hour}:${totData.min}:${totData.sec}`
        }
        response.data.data.report.push(totObj);
        if (tableFilter) {
          filterChange(tableFilter, response.data.data);
        } else {
          setTableData(response.data.data.report);
        }
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const popup = isModalOpen ? (
    <div className="popup-parent" onClick={(e) => modalWrapperClick(e)}>
      <div className="popup-wrapper">
        <div
          className="pop-up-close"
          onClick={() => {
            handleModalClose();
          }}
        >
          x
        </div>
        <div className="pop-content">
          <h5>Machine Utilization Details</h5>
          <table className="pop-up-table">
            <tr>
              <td>Start Date </td>
              <td>{dateFormat(showData.start_time)}</td>
            </tr>
            <tr>
              <td>End Date </td>
              <td>{dateFormat(showData.end_time)}</td>
            </tr>
            <tr>
              <td>Customer Name </td>
              <td>{showData.customer_name}</td>
            </tr>
            <tr>
              <td>Sort Name </td>
              <td>{showData.sort_name}</td>
            </tr>
            <tr>
              <td>Group Name </td>
              <td>{showData.group_name}</td>
            </tr>

            <tr>
              <td>Lot No. </td>
              <td>{showData.lot_number}</td>
            </tr>
            <tr>
              <td>Machine Code </td>
              <td>{showData.machine_code}</td>
            </tr>
            <tr>
              <td>Machine </td>
              <td>{showData.machine_name}</td>
            </tr>
            <tr>
              <td>Machine Hours </td>
              <td>{showData.machine_hours}</td>
            </tr>
            <tr>
              <td>Material </td>
              <td>{showData.material}</td>
            </tr>

            <tr>
              <td>Operation Name</td>
              <td>{showData.operation_name}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  ) : null;

  const handleChange = (event) => {
    resetRequired(event);
    switch (event.target.name) {
      case 'from':
        setFrom(event.target.value);
        if (event.target.value) {
          document.getElementsByClassName('to')[0].classList.add('require');
        } else {
          document.getElementsByClassName('to')[0].classList.remove('require');
        }
        break;
      case 'to':
        setTo(event.target.value);
        break;
    }
  };

  const handleCustomerChange = (event) => {
    let selected = [];
    let fliterStr = [];
    if (event.length == 0) {
      event = [{ label: 'All', value: '' }];
    }
    if (event.length > 1 && event[0].label == 'All') {
      event.splice(0, 1);
    }
    if (event.length > 1 && event[event.length - 1].label == 'All') {
      event.splice(0, event.length - 1);
    }
    event.forEach(function (item, index) {
      if (item.value) {
        selected.push(item.value);
        fliterStr.push(`filters[user][id][$in][${index}]=${item.value}`);
      }
    });
    setCustomer(selected);
    setDefaultCustomer(event);
    pullDownReset('customer');
    setCustomerSort([]);
    setDefaultCustomerSort([{ label: 'All', value: '' }]);
    setSortOptions([]);
    getSorts(fliterStr.join('&'));
  };

  const handleCustomerSortChange = (event) => {
    let selected = [];
    if (event.length == 0) {
      event = [{ label: 'All', value: '' }];
    }
    if (event.length > 1 && event[0].label == 'All') {
      event.splice(0, 1);
    }
    if (event.length > 1 && event[event.length - 1].label == 'All') {
      event.splice(0, event.length - 1);
    }
    event.forEach(function (item, index) {
      selected.push(item.value);
    });
    setCustomerSort(selected);
    setDefaultCustomerSort(event);
    pullDownReset('customerSort');
  };

  const machineChange = (event) => {
    let selected2 = [];
    if (event.length == 0) {
      event = [{ label: 'All', value: '' }];
    }
    if (event.length > 1 && event[0].label == 'All') {
      event.splice(0, 1);
    }
    if (event.length > 1 && event[event.length - 1].label == 'All') {
      event.splice(0, event.length - 1);
    }
    event.forEach(function (item, index) {
      selected2.push(item.value);
    });
    setMachineData(selected2);
    setDefaultMachine(event);
    pullDownReset('machine');
  };

  const clearFormData = () => {
    setFrom('');
    setTo('');
    setCustomer([]);
    setDefaultCustomer([{ label: 'All', value: '' }]);
    setCustomerSort([]);
    setDefaultCustomerSort([{ label: 'All', value: '' }]);
    setMachineData([]);
    setDefaultMachine([{ label: 'All', value: '' }]);
    resetRequired();
    document.getElementsByClassName('to')[0].classList.remove('require');
  };

  const getCustomers = () => {
    axios
      .get(baseUri() + 'users?filters[userType][$eq]=4', {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let options = [{ label: 'All', value: '' }];
        response.data.forEach(function (item, index) {
          options.push({ label: item.userCode + ' - ' + item.name, value: item.id });
        });
        setUserOptions(options);
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const getSorts = (query) => {
    setCustomerSort([]);
    showLoader();
    axios
      .get(baseUri() + `user-sorts?${query}`, {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let options = [{ label: 'All', value: '' }];
        response.data.data.forEach(function (item, index) {
          options.push({ label: item.attributes.sortName, value: item.id });
        });
        setSortOptions(options);
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const getMachines = () => {
    showLoader();
    axios
      .get(
        baseUri() +
          `machines?populate[0]=groupOps&populate[1]=groupOps.group&populate[2]=groupOps.operations&sort[0]=id:desc`,
        {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        let options = [];
        let groupInfo = [];
        response.data.data.forEach(function (item, index) {
          options.push({ label: item.attributes.machineCode + ' - ' + item.attributes.description, value: item.id });
          groupInfo.push({ id: item.id, groupOps: item.attributes.groupOps });
        });
        setMachineOptions(options);
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const columns = [
    {
      name: 'Action',
      cell: (row) => (
        <div className="action-container">
          <ActionBtn
            data-tag="allowRowEvents"
            className="btn-rad ml-10"
            onClick={() => {
              handleModalOpen(row.id);
            }}
          >
            {' '}
            <EvaIcon name="eye" />{' '}
          </ActionBtn>
        </div>
      ),
    },
    {
      name: 'Customer',
      selector: (row) => `${row.customer_name}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Customer Sort',
      selector: (row) => `${row.sort_name}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Group Name',
      selector: (row) => `${row.group_name}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Machine',
      selector: (row) => `${row.machine_name}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Code / Hours',
      selector: (row) => `${row.machine_code + ' ' + row.machine_hours}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Operation',
      selector: (row) => `${row.operation_name}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Lot No.',
      selector: (row) => `${row.lot_number}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Material',
      selector: (row) => `${row.material}`,
      sortable: true,
      maxWidth: '45%',
    },
  ];
  const filterColumns = ['customer_name', 'sort_name', 'material', 'group_name', 'operation_name', 'machine_name'];

  const filterChange = (searchInput, newData = false) => {
    setTableFilter(searchInput);
    let available = [];
    if (newData) {
      available = newData;
    } else {
      available = itemData;
    }
    if (searchInput) {
      let filteredData = getFiltered(searchInput, available, filterColumns, true);
      setTableData(filteredData);
    } else {
      setTableData(available);
    }
  };

  return (
    <>
      {popup}
      <SEO title="Machine Utilization Report Generator" />
      <Row>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header> Machine Utilization Report Generator</header>
            <CardBody className="cardbody">
              <Row>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Customer <span className="red">*</span>
                  </label>
                  <SelectField
                    fullWidth
                    onChange={(e) => handleCustomerChange(e)}
                    options={userOptions}
                    id="customer"
                    name="customer"
                    placeholder="Select Customer *"
                    value={defaultCustomer}
                    isMulti
                    multiple
                    className="pull-down multi-select"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Customer Sort</label>
                  <SelectField
                    fullWidth
                    onChange={(e) => handleCustomerSortChange(e)}
                    options={sortOptions}
                    id="customerSort"
                    name="customerSort"
                    placeholder="Select Customer Sort"
                    value={defaultCustomerSort}
                    isMulti
                    multiple
                    className="pull-down multi-select"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Machine</label>
                  <SelectField
                    fullWidth
                    onChange={(e) => machineChange(e)}
                    options={machineOptions}
                    id="machine"
                    name="machine"
                    placeholder="Select Machine"
                    value={defaultMachine}
                    isMulti
                    multiple
                    className="pull-down multi-select"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>From Date</label>
                  <Input fullWidth size="Small">
                    <input
                      type="date"
                      name="from"
                      placeholder="From Date"
                      onChange={(e) => handleChange(e)}
                      value={from}
                      className="from"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>To Date</label>
                  <Input fullWidth size="Small">
                    <input
                      type="date"
                      name="to"
                      placeholder="To Date"
                      onChange={(e) => handleChange(e)}
                      value={to}
                      className="to"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 12 }}>
                  <Button status="Success" type="button" className="btnrad" shape="" halfWidth onClick={postApi}>
                    Get Report
                  </Button>
                  &nbsp; &nbsp;
                  <Button status="danger" type="button" className="btnrad" shape="" halfWidth onClick={clearFormData}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>Machine Utilization Report</header>
            <CardBody>
              {showDownload && (
                <p>
                  <CSVLink data={tableData}>Download Excel</CSVLink>
                </p>
              )}
              <Input fullWidth size="Small">
                <input
                  type="text"
                  onChange={(e) => filterChange(e.target.value)}
                  placeholder="Filter"
                  name="tableFilter"
                  value={tableFilter}
                />
              </Input>
              <DataTable
                columns={columns}
                data={tableData}
                fixedHeader
                allowOverflow={false}
                overflowY={false}
                overflowX={false}
                wrap={false}
                highlightOnHover={true}
                pagination={true}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default MachineUtilizationReportPage;
